import React from "react";
import "../css/sposer.css";
const Sponser = () => {
  return (
    <div>
      <h1 className="hh">Sposer :</h1>
      <div className="sponser">
        <div className="item">
          <img
            src={require("../images/images.png")}
            height="150px"
            width="150px "
            style={{ borderRadius: "10px" }}
            alt=""
          />
          <h3>Bimo Bank</h3>
        </div>
        <div className="item">
          <img
            src={require("../images/images (1).png")}
            height="150px"
            width="150px "
            style={{ borderRadius: "10px" }}
            alt=""
          />
          <h3>Nike</h3>
        </div>
        <div className="item">
          <img
            src={require("../images/images (2).png")}
            height="150px"
            width="150px "
            style={{ borderRadius: "10px" }}
            alt=""
          />
          <h3>Adidas</h3>
        </div>
        <div className="item">
          <img
            src={require("../images/images (3).png")}
            alt=""
            height="150px"
            width="150px "
            style={{ borderRadius: "10px" }}
          />
          <h3>Puma</h3>
        </div>
      </div>
    </div>
  );
};

export default Sponser;
