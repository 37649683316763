import React from "react";
import FormDialog from "./Dialog";
import Rating from "@mui/material/Rating";
import '../css/item.css'
import { Link } from "react-router-dom";

const CourseItem = () => {
  return (
    <div>
      <h1 className="hh">Sposer :</h1>
    <div className="conti">
      <div className="up">
        <img
          src={require("../images/images (4).png")}
          height={"100%"}
          width={"100%"}
          alt=""
        />
      </div>
      <div className="dowm">
        <h2>Course name</h2>
        <div className="about">
          <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
          <Rating
            name="half-rating-read"
            defaultValue={2.5}
            precision={0.5}
            readOnly
          />
        </div>
        <div className="butt">
          <FormDialog/>
          <button className="buten2">
            <Link to= {'/course'} className="Link" style={{
              textDecoration : "none" ,
              color : "white",
              width :"100%"
            }}>
              Viwe
            </Link>
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CourseItem;
