import React from "react";
import "../css/About.css";
import "../css/responsive/About.css";
import Nav from "./Nav";
import Footer from "./Footer";
const About = () => {
  return (
    <div
      style={{
        background: "linear-gradient(#5d9cd6, #0d5aa4)",
      }}
    >
      <Nav />
      <div className="All">
        <div className="le">
          {/* <div className="photo">
            <img
              src={require("../images/ProgRyhming.png")}
              height={"75px"}
              width={"100"}
              alt=""
            />
          </div> */}
          <h1>
            <span className="one">Educational Traning Plaform</span>
            <br />
            <span className="two">Traning Camps</span>
          </h1>
          <p>
           Welcome to <span style={{
              fontSize : "30px"
            }}>PR camp !</span>  Empowering minds through <br/>
            transformative educational training. Join our dynamic training camps <br/>
            designed to unlock potential, enhance skills, and ignite passion.<br/>
            Whether you're looking to boost your career, explore new hobbies, or<br/>
            develop essential life skills, we've got something for everyone! 🚀<br/>
            Let's learn, grow, and succeed together!
          </p>
        </div>
        <div className="re">
          <img src={require("../images/camp.png")} className = "img"alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
