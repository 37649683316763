import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FormDialog from "./Dialog";
import Rating from "@mui/material/Rating";
import "../css/item.css";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import "../css/list.css";
import Footer from "./Footer";
import noDataImage from "../images/folder.png";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const CourseList = () => {
  const { sectionId } = useParams();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const sectionTitle = location.state?.sectionTitle;

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `https://training.progrhyming.com/platform/user/courses/section/${sectionId}`,
          {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiaWF0IjoxNzIwNDM5MzQxfQ.jyqX_-dEAp9oX8Ts2vx_QJ_29gZs6rGhNAjYnao5Nb0`,
            },
          }
        );

        console.log(response.data.data);

        setCourses(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setError("Failed to load courses.");
        setLoading(false);
      }
    };

    fetchCourses();
  }, [sectionId]);

  const handleCardClick = (
    courseId,
    courseN,
    courseD,
    courseR,
    courseI,
    url
  ) => {
    navigate(`/course/${courseId}`, {
      state: {
        courseN: courseN,
        courseD: courseD,
        courseR: courseR,
        courseI: courseI,
        url: url,
      },
    });
  };
  return (
    <div className="vh">
      <div className="Listofcourse">
        <Nav />
        <h1 className="hh">
          Courses for <span style={{ color: "#f59022" }}>{sectionTitle}:</span>
        </h1>
        <div className="course-list">
          {loading ? (
            <div style={{ width: "50%", margin: "auto", display: "block" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 0 150px 0  ",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="warning" />
              </Box>
            </div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : courses.length === 0 ? (
            <div className="no-data">
              <img
                src={noDataImage}
                alt="No courses available"
                style={{ width: "50%", margin: "auto", display: "block" }}
              />
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                No courses available for this section.
              </p>
            </div>
          ) : (
            courses.map((course, id) => (
              <div className="contii" key={id} style = {{
              
              }}>
                {/* {console.log("id::", course.id)} */}
                <div className="up">
                  <img
                    src={`https://panel.progrhyming.com/storage/${course.image}`}
                    height={"100%"}
                    width={"100%"}
                    alt=""
                  />
                </div>
                <div className="dowm">
                  <h2>{course.name}</h2>
                  <div className="about">
                    {/* <p>{course.description}</p> */}
                    <Rating
                      name="half-rating-read"
                      defaultValue={course.rate}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                  <div className="butt">
                    <button className="buten2">
                    <FormDialog />
                    </button>
                    <button
                      className="buten2"
                      onClick={() =>
                        handleCardClick(
                          course.id,
                          course.name,
                          course.description,
                          course.rate,
                          course.image
                        )
                      }
                    >
                      <div
                        className="Link"
                        style={{
                          textDecoration: "none",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        <img src={require('../images/list.png')} height={"33px"} alt="" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CourseList;
