/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "../css/Map.css";

const Map = () => {
  return (
    <div>
      <iframe
        className="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2972.742655270172!2d35.78027186026655!3d35.51404263925483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1526ad006851494b%3A0x755d5c1e1e47244e!2sProgRhyming%20Company!5e1!3m2!1sar!2s!4v1725107965917!5m2!1sar!2s"
        height={"400"}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
