import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";

export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem
          disablePadding
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <ListItemText>
            <Link to="/" className="Link">
              <h1>Home</h1>
            </Link>
          </ListItemText>
          <ListItemText>
            <Link to="/Courses" className="Link">
              <h1>Courses</h1>
            </Link>
          </ListItemText>
          <ListItemText>
            <Link to="/aboutus" className="Link">
              <h1>About us</h1>
            </Link>
          </ListItemText>
          <ListItemText>
            <Link to="/Contactus" className="Link">
              <h1>Contact us</h1>
            </Link>
          </ListItemText>
        </ListItem>
      </List>{" "}
    </Box>
  );
  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <img
          src={require("../images/justified_6631502.png")}
          height={"40px"}
          alt=""
        />
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
