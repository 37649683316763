import "../css/AD.css";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import noDataImage from "../images/folder.png";

export default function SimpleSlider() {
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get("https://training.progrhyming.com/platform/user/advertisements/", {
      })
      .then((response) => {
        console.log("Response Data:", response.data);
        setAds(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplaySpeed: 2500,
  };

  // if (ads.length === 0) {
  //   return (
  //     <Box sx={{ display: "flex", flexDirection: "column"  , margin  :"0 0 150px 0  " , alignItems : "center"}}>
  //       <h1 className=" hh">Advertisements :</h1>
  //       <CircularProgress color="inherit" />
  //     </Box>
  //   );
  // }
  return (
    <div className="all">
      <h1 className="hh">Advertisements :</h1>
      {loading ? (
            <div style={{ width: "50%", margin: "auto", display: "block" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 0 150px 0  ",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="warning" />
              </Box>
            </div>
          )  : ads.length === 0 ? (
            <div className="no-data">

              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize : "30px",
                  margin:"40px"
                }}
              >
                No Advertisements to show now.
              </p>
            </div>
          ) : (
      <Slider {...settings} className="slider">
        {ads.map((ad, index) => (
          <div key={index} className="Add">
            <img
              src={`https://panel.progrhyming.com/storage/${ad.image}`}
              alt={ad.title ? ad.title : "Ad image"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          </div>
        ))}
        
      </Slider>
      )}
    </div>
  );
}
