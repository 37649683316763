import React from "react";
import "../css/Home.css";
import Nav from "../components/Nav";
import SimpleSlider from "./SimpleSlider";
import Sponser from "./Sponser";
import Services from "../components/Services";
import "../css/responsive/Home.css";
import CourseItem from "./CourseItem";
import Staff from "../components/Staff";
import Footer from "./Footer";
import MostRate from "./MostRate";

const Home = () => {
  return (
    <div className="ap">
      <Nav />
      <div className="back">
        <div className="container">
          <div className="left">
            <h1 style={{ fontSize: "43px", fontWeight: "bold" }} className="hh">
              🌐 Code Your Future with Us! 🚀
            </h1>
            <p>
              Welcome to{" "}
              <span style={{ color: "#0d5aa4", fontSize: "30px" }}>Prog</span>
              <span style={{ color: "#f4942b", fontSize: "30px" }}>
                Rhyming
              </span>{" "}
              your go-to e-learning destination for mastering programming!
              Whether you're a beginner or looking to level up your skills, we
              offer a variety of interactive courses, hands-on projects, and a
              vibrant community to support your journey. Join us and transform
              your passion for coding into real-world skills!
            </p>
          </div>
          <div className="rieght">
            <img
              src={require("../images/1.png")}
              alt="fs"
              height="100%"
              width="100%"
              style={{ borderRadius: "20px" }}
            />
          </div>
        </div>
      </div>
      <SimpleSlider />
      <Services />
      <MostRate />
      <Footer />
    </div>
  );
};

export default Home;
