// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import CoursesViwe from "./components/CoursesViwe";
import Course from "./components/Course";
import Video from '../src/components/Video'
import CourseList from "./components/CourseList";
import TemporaryDrawer from "./components/Test";

function App() {
  return (
    <>
    
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/courses" element={<CoursesViwe/>} />
        <Route path="/course/:courseId" element = {<Course/>}/>
        <Route path="/video" element= {<Video/>}/>
        <Route path="/courses/:sectionId" element={<CourseList />} />
      </Routes>
      {/* <TemporaryDrawer/> */}
      {/* <Course/> */}
    </>
  );
}

export default App;
