import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import FormDialog from "./Dialog";
import Rating from "@mui/material/Rating";
import "../css/item.css";
import axios from "axios";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
function MostRate() {
  const [courses, setCourse] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          `https://training.progrhyming.com/platform/user/courses/most-rated?limit=5`
        );
        console.log(response.data.data);
        setCourse(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetch();
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    autoplay : true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  if (courses.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 0 150px 0  ",
          alignItems: "center",
        }}
      >
        <h1 className=" hh">Top Rated Courses :</h1>
        <CircularProgress color="inherit" />
      </Box>
    );
  }
  return (
    <>
      <h1
        className="hh"
      >
        Top Rated Courses :
      </h1>
      <div
        className="slider-container"
        style={{
          display: "block",
          width: "83%",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Slider {...settings} style = {{marginTop : "20px"}}>
          {courses.map((course, id) => (
            <div className="conti" key={id}>
              <div className="up">
                <img
                  src={`https://panel.progrhyming.com/storage/${course.image}`}
                  height={"100%"}
                  width={"100%"}
                  alt={course.name}
                />
              </div>
              <div className="dowm">
                <h2>{course.name}</h2>
                <div className="about">
                  <Rating
                    name="half-rating-read"
                    defaultValue={course.rate}
                    precision={0.5}
                    readOnly
                  />
                </div>
                <div className="butt">
                  <button className="buten2">
                    <FormDialog id={course.id} />
                  </button>
                  <button className="buten2">
                    <Link
                      to={`/course/${course.id}`}
                      className="Link"
                      style={{
                        textDecoration: "none",
                        color: "white",
                        width: "100%",
                      }}
                    >
                      <img
                        src={require("../images/list.png")}
                        height={"33px"}
                        alt=""
                      />
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <Link
          to="/courses"
          style={{
            textDecoration: "none",
          }}
        >
          <button
            className="go"
            style={{
              margin: "40px auto",
            }}
          >
            <p style={{
              fontSize : "25px"
            }}> view all courses </p>
            <ArrowRightAltIcon style={{
              fontSize : "40px"
            }}/>
          </button>
        </Link>
      </div>
    </>
  );
}

export default MostRate;
