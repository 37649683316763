import React from "react";
import "../css/Services.css";
import "../css/responsive/sirveses.css";

const Services = () => {
  return (
    <div style={{}}>
      <h1 className="hh">Our Services :</h1>
      <div className="container1">
        <div className="item">
          <img
            src={require("../images/chat_777724.png")}
            alt=""
            height={"70px"}
          />
          <div className="cont">
            <h2>Chatting</h2>
            <p>
              Connect with fellow learners through our chat!
              <br />
              ask questions and get real-time support as
              <br />
              you navigate your programming journey
              <br />
            </p>
          </div>
        </div>
        <div className="item">
          <img
            src={require("../images/online-meeting_5070287.png")}
            alt=""
            height={"70px"}
          />
          <div className="cont">
            <h2>Meeting</h2>
            <p>
              Schedule meetings for personalized guidance
              <br /> and, collaboration on your projects.
              <br /> Let’s code together
            </p>
          </div>
        </div>
        <div className="item">
          <img
            src={require("../images/web_3138720.png")}
            alt=""
            height={"70px"}
          />
          <div className="cont">
            <h2>Courses</h2>
            <p>
              Access a variety of courses to enhance your skills,
              <br />
              from beginner to advanced topics.
              <br />
              Learn at your own pace with expert-led content.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
