import React, { useState, useEffect } from "react";
import "../css/nav.css";
import { Link, useLocation } from "react-router-dom";
import '../css/responsive/Nav.css';
import Nav2 from "./Nav2";

const Nav = () => {
  const [activeLink, setActiveLink] = useState("/");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);

    // تنظيف التأثير عند تفكيك المكون
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobile) {
    return <Nav2 />;
  }

  return (
    <nav>
      <div className="nav">
        <div className="section1">
          <img
            src={require("../images/PR.png")}
            height="105px"
            alt=""
          />
        </div>
        <div className="section2">
          <ul className="list">
            <Link to="/" className="Link">
              <li className={activeLink === "/" ? "active" : ""}>Home</li>
            </Link>
            <Link to="/Courses" className="Link">
              <li className={activeLink === "/Courses" ? "active" : ""}>
                Courses
              </li>
            </Link>
            <Link to="/aboutus" className="Link">
              <li className={activeLink === "/aboutus" ? "active" : ""}>
                About us
              </li>
            </Link>
            <Link to="/Contactus" className="Link">
              <li className={activeLink === "/Contactus" ? "active" : ""}>
                Contact us
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
