import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from 'axios';

export default function FormDialog({ id }) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [phoneError, setPhoneError] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false); // حالة لإدارة ظهور الـ Snackbar

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handlefetcf = async (event) => {
    event.preventDefault();

    if (!/^[0][0-9]{9}$/.test(phone)) {
      setPhoneError("رقم الهاتف يجب أن يحتوي على 10 خانات ويبدأ بالصفر.");
      return;
    }

    const data = {
      name: name,
      phone: phone,
      address: address,
      course_id: id,
    };

    try {
      const response = await axios.post(
        `https://training.progrhyming.com/platform/user/forms`,
        data,
        // {
        //   headers: {
        //     Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiaWF0IjoxNzIwNDM5MzQxfQ.jyqX_-dEAp9oX8Ts2vx_QJ_29gZs6rGhNAjYnao5Nb0`
        //   }
        // }
      );
      handleClose();
      setAddress("");
      setName("");
      setPhone("");
      setPhoneError("");
      setSnackbarOpen(true);
    } catch (e) {
      console.log("Error:", e);
    }
  };

  return (
    <React.Fragment>
      <button onClick={handleClickOpen} style={{
          border : "none",
          background : "none"
        }}>
        <img src= {require('../images/done.png')} height={"30px"} alt="" />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Order this Course</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Full Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            required
            margin="dense"
            id="phone"
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              if (phoneError) setPhoneError("");
            }}
            error={!!phoneError}
            helperText={phoneError}
          />
          <TextField
            required
            margin="dense"
            id="address"
            name="address"
            label="Address"
            type="text"
            fullWidth
            variant="standard"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handlefetcf}>Order</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
          Order submitted successfully!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}