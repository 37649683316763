import React from "react";
import "../css/Contact.css";
import Map from "./Map";
import Nav from "./Nav";
import Footer from "./Footer";
const Contactus = () => {
  return (
    <div className="con" style={{
      background : "linear-gradient(#5d9cd6, #0d5aa4)"
    }}>
        <Nav/>
      <h1 className="hh" style={{
        color : "white",
        margin: "20px",
      }}>Contact Us :</h1>
      <div className="contanir">
        <div className="text">
          <div className="child">
            <img
              src={require("../images/earth.gif")}
              alt=""
              width={"50px"}
              height={"50px"}
              style={{ borderRadius: "15px", objectFit: "cover" }}
            />
            <div className="child1">
              <h3>Location</h3>
              <p>Syria , Lattakia</p>
            </div>
          </div>
          <div className="child">
            <img
              src={require("../images/24-hours-support.gif")}
              alt=""
              height={"50px"}
              width={"50px"}
              style={{ borderRadius: "15px", objectFit: "cover" }}
            />
            <div className="child1">
              <h3>Phone</h3>
              <a href="tel:+963959532399" target="blanck">+963 959 532 399</a>
            </div>
          </div>
          <div className="child">
            <img
              src={require("../images/email.gif")}
              alt=""
              height={"50px"}
              width={"50px"}
              style={{ borderRadius: "15px", objectFit: "cover" }}
            />
            <div className="child1">
              <h3>Email</h3>
              <a href="mailto:progrhymingg@gmail.com">progrhymingg@gmail.com</a>
            </div>
          </div>
          <div className="child">
            <img
              src={require("../images/chat.gif")}
              alt=""
              height={"50px"}
              width={"50px"}
              style={{ borderRadius: "15px", objectFit: "cover" }}
            />
            <div className="child1">
              <h3>WhatsApp</h3>
              <p>+963 959 532 399</p>
            </div>
          </div>
        </div>
        <div className="Map">
          <Map />
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Contactus;
